// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-us-js": () => import("./../../../src/pages/about/about-us.js" /* webpackChunkName: "component---src-pages-about-about-us-js" */),
  "component---src-pages-about-awards-js": () => import("./../../../src/pages/about/awards.js" /* webpackChunkName: "component---src-pages-about-awards-js" */),
  "component---src-pages-about-blacklist-js": () => import("./../../../src/pages/about-blacklist.js" /* webpackChunkName: "component---src-pages-about-blacklist-js" */),
  "component---src-pages-about-for-legal-entities-js": () => import("./../../../src/pages/about/for-legal-entities.js" /* webpackChunkName: "component---src-pages-about-for-legal-entities-js" */),
  "component---src-pages-about-management-js": () => import("./../../../src/pages/about/management.js" /* webpackChunkName: "component---src-pages-about-management-js" */),
  "component---src-pages-about-news-js": () => import("./../../../src/pages/about/news.js" /* webpackChunkName: "component---src-pages-about-news-js" */),
  "component---src-pages-about-open-info-js": () => import("./../../../src/pages/about/open-info.js" /* webpackChunkName: "component---src-pages-about-open-info-js" */),
  "component---src-pages-about-partners-js": () => import("./../../../src/pages/about/partners.js" /* webpackChunkName: "component---src-pages-about-partners-js" */),
  "component---src-pages-became-partner-js": () => import("./../../../src/pages/became-partner.js" /* webpackChunkName: "component---src-pages-became-partner-js" */),
  "component---src-pages-contacts-advertising-js": () => import("./../../../src/pages/contacts/advertising.js" /* webpackChunkName: "component---src-pages-contacts-advertising-js" */),
  "component---src-pages-contacts-offices-js": () => import("./../../../src/pages/contacts/offices.js" /* webpackChunkName: "component---src-pages-contacts-offices-js" */),
  "component---src-pages-contacts-requisites-js": () => import("./../../../src/pages/contacts/requisites.js" /* webpackChunkName: "component---src-pages-contacts-requisites-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instructions-js": () => import("./../../../src/pages/instructions.js" /* webpackChunkName: "component---src-pages-instructions-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-question-answer-faq-js": () => import("./../../../src/pages/question-answer/faq.js" /* webpackChunkName: "component---src-pages-question-answer-faq-js" */),
  "component---src-pages-question-answer-phone-js": () => import("./../../../src/pages/question-answer/phone.js" /* webpackChunkName: "component---src-pages-question-answer-phone-js" */),
  "component---src-pages-question-answer-question-js": () => import("./../../../src/pages/question-answer/question.js" /* webpackChunkName: "component---src-pages-question-answer-question-js" */),
  "component---src-pages-refill-js": () => import("./../../../src/pages/refill.js" /* webpackChunkName: "component---src-pages-refill-js" */),
  "component---src-pages-services-blocking-js": () => import("./../../../src/pages/services/blocking.js" /* webpackChunkName: "component---src-pages-services-blocking-js" */),
  "component---src-pages-services-check-balance-js": () => import("./../../../src/pages/services/check-balance.js" /* webpackChunkName: "component---src-pages-services-check-balance-js" */),
  "component---src-pages-services-detail-js": () => import("./../../../src/pages/services/detail.js" /* webpackChunkName: "component---src-pages-services-detail-js" */),
  "component---src-pages-services-tariffs-js": () => import("./../../../src/pages/services/tariffs.js" /* webpackChunkName: "component---src-pages-services-tariffs-js" */),
  "component---src-pages-where-to-buy-js": () => import("./../../../src/pages/where-to-buy.js" /* webpackChunkName: "component---src-pages-where-to-buy-js" */),
  "component---src-pages-where-to-refill-js": () => import("./../../../src/pages/where-to-refill.js" /* webpackChunkName: "component---src-pages-where-to-refill-js" */),
  "component---src-templates-card-card-template-jsx": () => import("./../../../src/templates/card/card-template.jsx" /* webpackChunkName: "component---src-templates-card-card-template-jsx" */),
  "component---src-templates-news-article-template-jsx": () => import("./../../../src/templates/news/article-template.jsx" /* webpackChunkName: "component---src-templates-news-article-template-jsx" */),
  "component---src-templates-outlets-outlets-template-jsx": () => import("./../../../src/templates/outlets/outlets-template.jsx" /* webpackChunkName: "component---src-templates-outlets-outlets-template-jsx" */)
}

